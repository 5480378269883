<style lang="postcss">:global(pre[class*='language-'], code[class*='language-']) {
  text-align: left;
  white-space: pre;
  color: #d4d4d4;
  font-size: 14px;
  text-shadow: none;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 2;
  hyphens: none;
}

:global(pre[class*='language-']::selection, code[class*='language-']::selection) {
  text-shadow: none;
  background: #b3d4fc;
}

:global(pre[class*='language-']) {
  overflow: auto;
  background: #1f2430;
}

:global(:not(pre) > code[class*='language-']) {
  border-radius: 0.3em;
  color: #db4c69;
  background: #f9f2f4;
}

/*********************************************************
* Tokens
*/

:global(.namespace) {
  opacity: 0.75;
}

:global(.token.comment, .token.prolog, .token.doctype, .token.cdata) {
  color: #60697a;
}

:global(.token.punctuation) {
  color: #d4d4d4;
}

:global(.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted) {
  color: #b5cea8;
}

:global(.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted) {
  color: #bae67e;
}

:global(.token.operator, .token.entity, .token.url, .language-css .token.string, .style
    .token.string) {
  color: #f29e74;
  background: #1f2430;
}

:global(.token.atrule, .token.attr-value, .token.keyword) {
  color: #ffa759;
}

:global(.token.function) {
  color: #ca746b;
}

:global(.token.regex, .token.important, .token.variable) {
  color: #95e6cb;
}

:global(.token.important, .token.bold) {
  font-weight: bold;
}

:global(.token.italic) {
  font-style: italic;
}

:global(.token.constant) {
  color: #cbccc6;
}

:global(.token.class-name) {
  color: #d4bfff;
}

:global(.token.parameter) {
  color: #d4bfff;
}

:global(.token.interpolation) {
  color: #9cdcfe;
}

:global(.token.punctuation.interpolation-punctuation) {
  color: #569cd6;
}

:global(.token.boolean) {
  color: #569cd6;
}

:global(.token.property) {
  color: #9cdcfe;
}

:global(.token.selector) {
  color: #d7ba7d;
}

:global(.token.tag) {
  color: #569cd6;
}

:global(.token.attr-name) {
  color: #9cdcfe;
}

:global(.token.attr-value) {
  color: #ce9178;
}

:global(.token.entity) {
  color: #4ec9b0;
  cursor: unset;
}

:global(.token.namespace) {
  color: #4ec9b0;
}

/*********************************************************
* Language Specific
*/

:global(pre[class*='language-javascript'], code[class*='language-javascript']) {
  color: #4ec9b0;
}

:global(pre[class*='language-css'], code[class*='language-css']) {
  color: #ce9178;
}

:global(pre[class*='language-html'], code[class*='language-html']) {
  color: #d4d4d4;
}

:global(.language-html .token.punctuation) {
  color: #808080;
}

/*********************************************************
* Line highlighting
*/

:global(pre[data-line]) {
  position: relative;
}

:global(pre[class*='language-'] > code[class*='language-']) {
  position: relative;
  z-index: 1;
}

:global(.line-highlight) {
  position: absolute;
  left: 0;
  right: 0;
  padding: inherit 0;
  margin-top: 1em;
  background: #f7ebc6;
  box-shadow: inset 5px 0 0 #f7d87c;
  z-index: 0;
  pointer-events: none;
  line-height: inherit;
  white-space: pre;
}</style>
