<script>
import {
  EditIcon,
  SendIcon,
  InboxIcon,
  StarIcon,
  AlertOctagonIcon,
  TrashIcon,
} from 'svelte-feather-icons';

import TopBar from '../components/TopBar.svelte';
import MediumEditor from '../components/MediumEditor.svelte';

let subject = '';
let message = '';
</script>

<style lang="postcss">.email {
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --bg-opacity: 1;
  background-color: #2d3748;
  background-color: rgba(45, 55, 72, var(--bg-opacity));
  width: 100%;
  height: 100%;
  color: #636363;
}

ul.listing {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

ul.listing > li {
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  align-items: center;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 1rem;
}

ul.listing > li:hover {
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
}</style>

<div class="email">
  <div class="bg-gray-800 px-6 flex flex-col py-4 border-r border-gray-600 text-gray-100">
    <button
      class="px-6 py-2 bg-red-600 text-white rounded-xl font-semibold tracking-widest my-10 flex
      items-center text-sm">
      <span class="flex w-4 h-4 mr-4">
        <EditIcon />
      </span>
      Compose
    </button>
    <ul class="listing">
      <li>
        <span class="flex w-5 h-5 mr-4">
          <InboxIcon />
        </span>
        Inbox
      </li>
      <li>
        <span class="flex w-5 h-5 mr-4">
          <SendIcon />
        </span>
        Sent
      </li>
      <li>
        <span class="flex w-5 h-5 mr-4">
          <StarIcon />
        </span>
        Starred
      </li>
      <li>
        <span class="flex w-5 h-5 mr-4">
          <AlertOctagonIcon />
        </span>
        Spam
      </li>
      <li>
        <span class="flex w-5 h-5 mr-4">
          <TrashIcon />
        </span>
        Trash
      </li>
    </ul>
  </div>
  <div class="flex-grow bg-gray-100 flex flex-col">
    <div class="flex items-center">
      <div class="my-2 mx-6 font-medium">Write to Divyun</div>
      <TopBar  class="pl-6 flex-grow">
        <div class="flex-grow" />
      </TopBar>
    </div>
    <div class="flex flex-col bg-gray-200 px-4 pt-10 pb-5 border-b border-t border-gray-200">
      <div class="flex items-end px-6 pb-6">
        <img
          src="/avatar-lines.svg"
          alt="Divyun Raje Vaid"
          class="w-20 h-20 shadow-xl rounded-full border border-gray-300" />
        <div class="flex flex-col flex-grow justify-end font-semibold tracking-wide pl-3">
          <span class="px-4 text-gray-500 text-sm tracking-wider">To</span>
          <span class="bg-white rounded-full p-2 px-4 border-gray-300 border">
            Divyun Raje Vaid
            <small>&lt;mail@divyun.com&gt;</small>
          </span>
        </div>
        <a
          onClick="javascript:window.open('mailto:mail@divyun.com?subject={subject}&body={message}',
          'mail');event.preventDefault()"
          href="mailto:mail@divyun.com?subject={subject}&body={message}"
          class="flex bg-blue-600 text-white px-6 py-2 text-sm rounded-xl ml-4 mb-1">
          <span class="flex w-5 h-5 mr-4">
            <SendIcon />
          </span>
          Send
        </a>
      </div>
      <div class="flex px-6">
        <label
          class="border-gray-300 border rounded-l-full px-4 py-2 bg-white text-gray-700 font-medium
          tracking-wider">
          Subject
        </label>
        <input
          id="subject"
          class="px-4 py-2 rounded-r-full flex-grow border-gray-300 border-r border-t border-b"
          bind:value={subject} />
      </div>
    </div>
    <MediumEditor bind:value={message} />
  </div>
</div>
