<script>
import { XIcon, MinusIcon, Maximize2Icon } from 'svelte-feather-icons';
import { getContext } from 'svelte';

const windowHandler = getContext('window');
const routeObj = windowHandler.getRoute();
</script>

<style lang="postcss">.topbar {
  display: flex;
  font-size: 0.875rem;
  user-select: none;
  height: 100%;
  min-height: 2rem;
  opacity: 0.88;
  backdrop-filter: blur(5px);
}

.topbar__actions {
  display: flex;
}

.topbar__actions > button {
  height: 100%;
  width: 3rem;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 1;
  cursor: default;
}

.topbar__actions > button:hover {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #cbd5e0;
  background-color: rgba(203, 213, 224, var(--bg-opacity));
}

.topbar__actions > button:last-child:hover {
  --text-opacity: 1;
  color: #fed7d7;
  color: rgba(254, 215, 215, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #c53030;
  background-color: rgba(197, 48, 48, var(--bg-opacity));
}

:global(.topbar__actions__icon) {
  width: 1.25rem;
  height: 1.25rem;
}</style>

<div class="topbar {$$props.class || ''}" style={$$props.style || ''}>
  <div class="flex" style="width: calc(100% - 9rem)">
    <slot name="menu" />
    <div
      draggable="true"
      on:dragstart={(e) => {
        e.dataTransfer.dropEffect = 'move';
        windowHandler.startResizing({ move: true, x: e.x, y: e.y }, e);
      }}
      on:dragend={windowHandler.stopResizing}
      on:dblclick={windowHandler.toggleMaximize}
      class="flex-grow flex items-center px-4">
      <slot />
    </div>
  </div>
  <div class="topbar__actions">
    <button on:click|stopPropagation={windowHandler.minimize}>
      <MinusIcon class="topbar__actions__icon" />
    </button>
    <button on:click={windowHandler.toggleMaximize}>
      <Maximize2Icon class="topbar__actions__icon" />
    </button>
    <button on:click={windowHandler.close}>
      <XIcon class="topbar__actions__icon" />
    </button>
  </div>
</div>
