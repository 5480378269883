<script>
import { onMount, onDestroy } from 'svelte';
import MediumEditor from 'medium-editor';

let el;
export let value = '';

let editor;
onMount(() => {
  editor = new MediumEditor(el, {
    placeholder: { text: 'Type in a message for Divyun' },
  });
  editor.subscribe('editableInput', function() {
    value = el.textContent;
  });
});

onDestroy(() => {
  editor.destroy();
});
</script>

<style lang="postcss" global>@-webkit-keyframes -global-medium-editor-image-loading {
  :global(0%) {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  :global(100%) {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes -global-medium-editor-image-loading {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes -global-medium-editor-pop-upwards {
  :global(0%) {
    opacity: 0;
    -webkit-transform: matrix(0.97, 0, 0, 1, 0, 12);
    transform: matrix(0.97, 0, 0, 1, 0, 12);
  }

  :global(20%) {
    opacity: .7;
    -webkit-transform: matrix(0.99, 0, 0, 1, 0, 2);
    transform: matrix(0.99, 0, 0, 1, 0, 2);
  }

  :global(40%) {
    opacity: 1;
    -webkit-transform: matrix(1, 0, 0, 1, 0, -1);
    transform: matrix(1, 0, 0, 1, 0, -1);
  }

  :global(100%) {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes -global-medium-editor-pop-upwards {
  0% {
    opacity: 0;
    -webkit-transform: matrix(0.97, 0, 0, 1, 0, 12);
    transform: matrix(0.97, 0, 0, 1, 0, 12);
  }

  20% {
    opacity: .7;
    -webkit-transform: matrix(0.99, 0, 0, 1, 0, 2);
    transform: matrix(0.99, 0, 0, 1, 0, 2);
  }

  40% {
    opacity: 1;
    -webkit-transform: matrix(1, 0, 0, 1, 0, -1);
    transform: matrix(1, 0, 0, 1, 0, -1);
  }

  100% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

:global(.medium-editor-anchor-preview) {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  left: 0;
  line-height: 1.4;
  max-width: 280px;
  position: absolute;
  text-align: center;
  top: 0;
  word-break: break-all;
  word-wrap: break-word;
  visibility: hidden;
  z-index: 2000;
}

:global(.medium-editor-anchor-preview) :global(a) {
  color: #fff;
  display: inline-block;
  margin: 5px 5px 10px;
}

:global(.medium-editor-anchor-preview-active) {
  visibility: visible;
}

:global(.medium-editor-dragover) {
  background: #ddd;
}

:global(.medium-editor-image-loading) {
  -webkit-animation: medium-editor-image-loading 1s infinite ease-in-out;
  animation: medium-editor-image-loading 1s infinite ease-in-out;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  height: 40px;
  width: 40px;
}

:global(.medium-editor-placeholder) {
  position: relative;
}

:global(.medium-editor-placeholder:after) {
  content: attr(data-placeholder) !important;
  font-style: italic;
  position: absolute;
  left: 0;
  top: 0;
  white-space: pre;
  padding: inherit;
  margin: inherit;
}

:global(.medium-editor-placeholder-relative) {
  position: relative;
}

:global(.medium-editor-placeholder-relative:after) {
  content: attr(data-placeholder) !important;
  font-style: italic;
  position: relative;
  white-space: pre;
  padding: inherit;
  margin: inherit;
}

:global(.medium-toolbar-arrow-under:after), :global(.medium-toolbar-arrow-over:before) {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  left: 50%;
  margin-left: -8px;
  position: absolute;
  width: 0;
}

:global(.medium-toolbar-arrow-under:after) {
  border-width: 8px 8px 0 8px;
}

:global(.medium-toolbar-arrow-over:before) {
  border-width: 0 8px 8px 8px;
  top: -8px;
}

:global(.medium-editor-toolbar) {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  z-index: 2000;
}

:global(.medium-editor-toolbar) :global(ul) {
  margin: 0;
  padding: 0;
}

:global(.medium-editor-toolbar) :global(li) {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

:global(.medium-editor-toolbar) :global(li) :global(button) {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 1.33;
  margin: 0;
  padding: 15px;
  text-decoration: none;
}

:global(.medium-editor-toolbar) :global(li) :global(button:focus) {
  outline: none;
}

:global(.medium-editor-toolbar) :global(li) :global(.medium-editor-action-underline) {
  text-decoration: underline;
}

:global(.medium-editor-toolbar) :global(li) :global(.medium-editor-action-pre) {
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
  font-weight: 100;
  padding: 15px 0;
}

:global(.medium-editor-toolbar-active) {
  visibility: visible;
}

:global(.medium-editor-sticky-toolbar) {
  position: fixed;
  top: 1px;
}

:global(.medium-editor-relative-toolbar) {
  position: relative;
}

:global(.medium-editor-toolbar-active.medium-editor-stalker-toolbar) {
  -webkit-animation: medium-editor-pop-upwards 160ms forwards linear;
  animation: medium-editor-pop-upwards 160ms forwards linear;
}

:global(.medium-editor-action-bold) {
  font-weight: bolder;
}

:global(.medium-editor-action-italic) {
  font-style: italic;
}

:global(.medium-editor-toolbar-form) {
  display: none;
}

:global(.medium-editor-toolbar-form) :global(input),
  :global(.medium-editor-toolbar-form) :global(a) {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-form-row) {
  line-height: 14px;
  margin-left: 5px;
  padding-bottom: 5px;
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-input),
  :global(.medium-editor-toolbar-form) :global(label) {
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0;
  padding: 6px;
  width: 316px;
  display: inline-block;
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-input:focus),
    :global(.medium-editor-toolbar-form) :global(label:focus) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  box-shadow: none;
  outline: 0;
}

:global(.medium-editor-toolbar-form) :global(a) {
  display: inline-block;
  font-size: 24px;
  font-weight: bolder;
  margin: 0 10px;
  text-decoration: none;
}

:global(.medium-editor-toolbar-form-active) {
  display: block;
}

:global(.medium-editor-toolbar-actions:after) {
  clear: both;
  content: "";
  display: table;
}

:global(.medium-editor-element) {
  word-wrap: break-word;
  min-height: 30px;
}

:global(.medium-editor-element) :global(img) {
  max-width: 100%;
}

:global(.medium-editor-element) :global(sub) {
  vertical-align: sub;
}

:global(.medium-editor-element) :global(sup) {
  vertical-align: super;
}

:global(.medium-editor-hidden) {
  display: none;
}

:global(.medium-toolbar-arrow-under:after) {
  border-color: #428bca transparent transparent transparent;
  top: 60px;
}

:global(.medium-toolbar-arrow-over:before) {
  border-color: transparent transparent #428bca transparent;
}

:global(.medium-editor-toolbar) {
  background-color: #428bca;
  border: 1px solid #357ebd;
  border-radius: 4px;
}

:global(.medium-editor-toolbar) :global(li) :global(button) {
  background-color: transparent;
  border: none;
  border-right: 1px solid #357ebd;
  box-sizing: border-box;
  color: #fff;
  height: 60px;
  min-width: 60px;
  -webkit-transition: background-color .2s ease-in, color .2s ease-in;
  transition: background-color .2s ease-in, color .2s ease-in;
}

:global(.medium-editor-toolbar) :global(li) :global(button:hover) {
  background-color: #3276b1;
  color: #fff;
}

:global(.medium-editor-toolbar) :global(li) :global(.medium-editor-button-first) {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

:global(.medium-editor-toolbar) :global(li) :global(.medium-editor-button-last) {
  border-bottom-right-radius: 4px;
  border-right: none;
  border-top-right-radius: 4px;
}

:global(.medium-editor-toolbar) :global(li) :global(.medium-editor-button-active) {
  background-color: #3276b1;
  color: #fff;
}

:global(.medium-editor-toolbar-form) {
  background: #428bca;
  border-radius: 4px;
  color: #fff;
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-input) {
  background: #428bca;
  color: #fff;
  height: 60px;
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-input::-webkit-input-placeholder) {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-input:-moz-placeholder) {
  /* Firefox 18- */
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-input::-moz-placeholder) {
  /* Firefox 19+ */
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
}

:global(.medium-editor-toolbar-form) :global(.medium-editor-toolbar-input:-ms-input-placeholder) {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
}

:global(.medium-editor-toolbar-form) :global(a) {
  color: #fff;
}

:global(.medium-editor-toolbar-anchor-preview) {
  background: #428bca;
  border-radius: 4px;
  color: #fff;
}

:global(.medium-editor-placeholder:after) {
  color: #357ebd;
}

:global(.text-editor) {
  padding: 3rem;
}

:global(.text-editor) :global(h2) {
  font-size: 1.5rem;
}

:global(.text-editor) :global(h3) {
  font-size: 1.25rem;
}</style>

<div class="text-editor">
  <div bind:this={el} />
</div>
