<script>
import {
  XIcon,
  PlusIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  RotateCwIcon,
  HomeIcon,
  LockIcon,
  StarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'svelte-feather-icons';

import TopBar from '../components/TopBar.svelte';

let containerWidth;
let containerEl;

let showScroll;

function calculateScroll() {
  if (!containerEl) return false;
  return containerEl.clientWidth < containerEl.scrollWidth;
}

let tabs = [
  {
    name: 'Browser - Divyun Raje Vaid',
    url: 'https://divyun.com',
  },
];

let scrollInterval;

let activeTab = 0;

$: showScroll = calculateScroll(containerWidth, tabs.length);
</script>

<style lang="postcss">.browser {
  display: flex;
  flex-direction: column;
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
}

.tab-container {
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  scrollbar-width: none;
}

.tab-container::-webkit-scrollbar {
  display: none;
}</style>

<div class="browser">
  <header class="w-full text-sm">
    <TopBar class="bg-gray-900 text-white w-full">
      <div class="flex" slot="menu" style="max-width: calc(100% - 4rem);">
        <div
          class:hidden={!showScroll}
          class="flex hover:bg-gray-800 items-center justify-center px-1"
          on:mousedown={() => {
            clearInterval(scrollInterval);
            scrollInterval = setInterval(() => {
              containerEl.scrollBy({ left: -25, behavior: 'smooth' });
            }, 100);
          }}
          on:mouseup={() => clearInterval(scrollInterval)}>
          <ChevronLeftIcon class="px-1 w-8 h-8 text-white" />
        </div>
        <div class="tab-container" bind:offsetWidth={containerWidth} bind:this={containerEl}>
          {#each tabs as tab, index}
            <div
              class="tab flex justify-between items-center px-2 cursor-default select-none w-64 {activeTab === index ? 'bg-gray-800' : 'bg-gray-900'}"
              on:click={() => {
                activeTab = index;
              }}>
              <img src="/favicon.png" class="h-5 w-5 rounded mr-1" alt="favicon" />
              <div
                class="whitespace-no-wrap overflow-x-hidden text-gray-500"
                style="width: calc(100% - 4rem);">
                {tab.name}
              </div>
              <div
                class="hover:bg-gray-700 rounded tab--close"
                on:click|stopPropagation={() => {
                  tabs.splice(index, 1);
                  tabs = tabs;
                }}>
                <XIcon class="w-4 h-4 text-white" />
              </div>
            </div>
          {/each}
        </div>
        <div
          class:hidden={!showScroll}
          class="flex hover:bg-gray-800 items-center justify-center px-1"
          on:mousedown={() => {
            clearInterval(scrollInterval);
            scrollInterval = setInterval(() => {
              containerEl.scrollBy({ left: 25, behavior: 'smooth' });
            }, 100);
          }}
          on:mouseup={() => clearInterval(scrollInterval)}>
          <ChevronRightIcon class="px-1 w-8 h-8 text-white" />
        </div>
        <div
          class="text-xs flex justify-between items-center bg-gray-900 px-2 hover:bg-gray-800"
          on:click={() => {
            tabs = [...tabs, { name: 'Home', url: '' }];
            activeTab = tabs.length - 1;
          }}>
          <PlusIcon class="w-5 h-5 text-white" />
        </div>
      </div>

      <div class="h-full w-full" />
    </TopBar>
  </header>
  <!-- Address bar -->
  <div class="flex-grow flex flex-col">
    <div class="bg-gray-800 flex justify-between items-center w-full py-1 z-0">
      <!-- Actions -->
      <div id="actions" class="flex px-2 flex-none text-white items-center">
        <div id="back" class="mx-2">
          <ArrowLeftIcon class="w-6 h-6" />
        </div>
        <div id="forward" class="mx-2">
          <ArrowRightIcon class="w-6 h-6" />
        </div>
        <div id="reload" class="mx-2">
          <RotateCwIcon class="w-5 h-5" />
        </div>
        <div
          id="home"
          class="mx-2"
          on:click={() => {
            tabs[activeTab].name = 'Home';
          }}>
          <HomeIcon class="w-5 h-5" />
        </div>
      </div>
      <div
        class="flex-grow flex items-center bg-gray-900 text-white rounded"
        style="max-width: 61rem;">
        <LockIcon class="w-5 h-5 mx-2 mt-1" />
        |
        <input
          type="text"
          class="w-full bg-gray-900 text-white py-1 ml-2 flex-grow"
          value="WIP" />
        <StarIcon class="w-6 h-6 mx-2" />
      </div>
      <div class="flex flex-none mx-2 items-center">
        <img class="w-5 h-5 rounded-full mx-2" src="/favicon.png" alt="" />
        <button
          id="settings"
          class="px-2 ml-1 text-white text-xl pb-1 cursor-default hover:bg-gray-700">
          ☰
        </button>
      </div>
    </div>
    <div class="bg-white flex justify-center flex-col items-center flex-grow">
      <h1>Welcome to Divyun.com</h1>
    </div>
  </div>
</div>
