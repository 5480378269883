<script>
import TopBar from '../components/TopBar.svelte';
</script>

<style lang="postcss">.profile {
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  color: #636363;
}

ul.menu {
  display: flex;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  font-weight: 600;
}

ul.menu > li {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
  transition: 250ms;
}

ul.menu > li:hover {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}</style>

<div class="profile">
  <div class="bg-gray-300 flex">
    <div class="bg-gray-100 shadow">
      <img src="/linkedin.svg" class="my-2 mx-4 w-8 h-8" alt="Profile" />
    </div>
    <ul class="menu">
      <li>Profile</li>
      <li>
        <a href="https://elite.fyi" target="_blank" class="hover:no-underline hover:text-gray-700">
          My network 
        </a>
      </li>
      <li>Job</li>
    </ul>
    <TopBar  class="flex-grow" style="height: 3rem">
      <div class="flex-grow" />
    </TopBar>
  </div>
  <div class="bg-gray-100 flex-grow overflow-y-auto flex flex-col p-10">
    <h2 class="text-2xl font-semibold mx-6">Divyun Raje Vaid.</h2>
    <h3 class="text-lg mb-10 mx-6">I make silicon rocks think.</h3>
    <div class="flex bg-gray-200 rounded-xl h-32 items-center">
      <div class="text-lg font-medium text-blue-800 tracking-wide px-10 flex-grow">Reach Me</div>
      <div class="flex h-full px-8">
        <div class="flex flex-col items-end">
          <a
            href="https://in.linkedin.com/in/divyun"
            target="_blank"
            class="uppercase flex items-center justify-center text-xs bg-blue-600 rounded-xl
            shadow-2xl relative h-10 px-4 mb-4 -mt-2 -mr-16">
            <div
              class="w-6 h-4 absolute bottom-0 right-0 bg-blue-600 -mb-1 mr-1"
              style="transform: rotate(25deg) skew(34deg);" />
            <span class="z-10 text-blue-200 tracking-wider font-semibold">Connect with me</span>
          </a>
          <a
            href="https://in.linkedin.com/in/divyun"
            target="_blank"
            class="uppercase flex items-center justify-center text-xs bg-indigo-600 rounded-xl
            shadow-2xl relative h-10 px-4 mt-8 -mr-20">
            <div
              class="w-6 h-4 absolute top-0 right-0 bg-indigo-600 -mt-1 mr-1"
              style="transform: rotate(-25deg) skew(-34deg);" />
            <span class="z-10 text-indigo-200 tracking-wider font-semibold">Follow me</span>
          </a>
        </div>
        <div class="-mt-32 px-8">
          <img
            src="/avatar-lines.svg"
            alt="Divyun"
            class="w-64 h-64"
            style="filter: drop-shadow(0 20px 25px rgba(0, 0, 0, 0.1)) drop-shadow(0 10px 10px
            rgba(0, 0, 0, 0.04)); transform: rotateY(180deg);" />
        </div>
        <div class="-ml-16 -mt-2">
          <a
            href="https://www.linkedin.com/messaging/compose/?connId=divyun"
            target="_blank"
            class="uppercase flex items-center justify-center text-xs bg-green-600 rounded-xl
            shadow-2xl relative h-10 px-4">
            <div
              class="w-6 h-4 absolute bottom-0 left-0 bg-green-600 -mb-1 ml-2"
              style="transform: rotate(-25deg) skew(-34deg);" />
            <span class="z-10 text-green-200 tracking-wider font-semibold">Message Me</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
