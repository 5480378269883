<script>
  import Prism from 'prismjs';
  import TopBar from '../components/TopBar.svelte';
  import PrismTheme from '../components/PrismTheme.svelte';

  const foldersAndFiles = [
    {
      name: 'Folder 1',
      type: 'folder',
      files: [
        {
          name: 'file1',
          type: 'svelte',
        },
      ],
    },
    {
      name: 'file2',
      type: 'js',
    },
    {
      name: 'file2',
      type: 'json',
    },
  ];

  const sampleCode = Prism.highlight(
    `const purgecss = require('@fullhuman/postcss-purgecss')({
  content: ['./**/**/*.html', './**/**/*.svelte'],
  whitelistPatterns: [/svelte-/],
  defaultExtractor: content => content.match(/[\\w-/:]+(?<!:)/g) || [],
});

const production = !process.env.ROLLUP_WATCH;

module.exports = {
  plugins: [
    require('postcss-import')(),
    require('tailwindcss'),

    // only needed if you want to purge
    ...(production ? [purgecss] : []),
  ],
};`,
    Prism.languages.javascript,
    'javascript',
  );
</script>

<style lang="postcss">.code-editor {
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  background-color: #1f2430;
  color: #60697a;
}

.top-bar-wrapper {
  flex-grow: 1;
}

.top-bar-wrapper > div {
  flex-grow: 1;
}

.entry {
  flex-grow: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.16rem 1.5rem 0.16rem 1.5rem;
}

li > .entry {
  padding: 0.16rem 1.5rem 0.16rem 2rem;
}

.entry:hover {
  background-color: #191f2a;
}</style>

<PrismTheme />

<div class="code-editor">
  <div class="font-semibold">
    <h4 class="uppercase tracking-wider text-sm px-6 my-2">Folders</h4>
    <span class="entry text-sm">
      <img src="/code-editor/folder_opened.png" alt="folder" class="h-4 mr-2" />
      divyun
    </span>
    <ul class="flex flex-col text-sm" style="min-width: 10rem;">
      {#each foldersAndFiles as { name, type, files }}
        <li class="leading-relaxed flex">
          <span class="entry">
            {#if type === 'folder'}
              <img src="/code-editor/folder.png" alt={type[0]} class="h-4 mr-2" />
            {:else}
              <img src="/code-editor/file_type_{type}@2x.png" alt={type[0]} class="h-4 mr-2" />
            {/if}
            {name}{type === 'folder' ? '' : `.${type}`}
          </span>
        </li>
      {/each}
    </ul>
  </div>
  <div class="flex-grow flex flex-col">
    <div class="flex">
      <div class="flex">
        <div
          on:click={() => {}}
          class="hover:bg-gray-700 px-4 cursor-pointer text-sm flex items-center">
          <span>Tab 1</span>
          <span class="text-lg pb-1 ml-3">×</span>
        </div>
        <div
          on:click={() => {}}
          class="hover:bg-gray-700 px-4 cursor-pointer text-sm flex items-center">
          <span>Tab 2</span>
          <span class="text-lg pb-1 ml-3">×</span>
        </div>
      </div>
      <div class="top-bar-wrapper">
        <TopBar>
          <div class="flex-grow" />
        </TopBar>
      </div>
    </div>
    <div class="flex py-4">
      <ul class="flex flex-col mr-6 justify-between cursor-default" style="font-size: 14px;">
        {#each sampleCode.split('\n') as _, index}
          <li class="flex">{index + 1}</li>
        {/each}
      </ul>
      <pre class="flex-grow">
        <code class="language-js">
          {@html sampleCode}
        </code>
      </pre>
    </div>
  </div>
</div>
