<style lang="postcss">@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-Thin.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-Thin.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-Thin.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-Thin.ttf') format('ttf');

  font-weight: 100;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-ThinItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-ThinItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-ThinItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-ThinItalic.ttf') format('ttf');

  font-weight: 100;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-ExtraLight.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-ExtraLight.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-ExtraLight.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-ExtraLight.ttf') format('ttf');

  font-weight: 200;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-ExtraLightItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-ExtraLightItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-ExtraLightItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-ExtraLightItalic.ttf') format('ttf');

  font-weight: 200;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-Light.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-Light.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-Light.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-Light.ttf') format('ttf');

  font-weight: 300;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-LightItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-LightItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-LightItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-LightItalic.ttf') format('ttf');

  font-weight: 300;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-Regular.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-Regular.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-Regular.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-Regular.ttf') format('ttf');

  font-weight: 400;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-RegularItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-RegularItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-RegularItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-RegularItalic.ttf') format('ttf');

  font-weight: 400;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-Medium.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-Medium.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-Medium.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-Medium.ttf') format('ttf');

  font-weight: 500;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-MediumItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-MediumItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-MediumItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-MediumItalic.ttf') format('ttf');

  font-weight: 500;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-SemiBold.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-SemiBold.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-SemiBold.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-SemiBold.ttf') format('ttf');

  font-weight: 600;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-SemiBoldItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-SemiBoldItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-SemiBoldItalic.ttf') format('ttf');

  font-weight: 600;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-Bold.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-Bold.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-Bold.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-Bold.ttf') format('ttf');

  font-weight: 700;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-BoldItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-BoldItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-BoldItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-BoldItalic.ttf') format('ttf');

  font-weight: 700;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-ExtraBold.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-ExtraBold.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-ExtraBold.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-ExtraBold.ttf') format('ttf');

  font-weight: 800;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-ExtraBoldItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-ExtraBoldItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-ExtraBoldItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-ExtraBoldItalic.ttf') format('ttf');

  font-weight: 800;

  font-style: italic;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-Black.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-Black.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-Black.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-Black.ttf') format('ttf');

  font-weight: 900;

  font-style: normal;
}

@font-face {
  font-family: Metropolis;

  src: url('/fonts/metropolis/woff2/Metropolis-BlackItalic.woff2') format('woff2'),
    url('/fonts/metropolis/eot/Metropolis-BlackItalic.eot') format('eot'),
    url('/fonts/metropolis/woff/Metropolis-BlackItalic.woff') format('woff'),
    url('/fonts/metropolis/ttf/Metropolis-BlackItalic.ttf') format('ttf');

  font-weight: 900;

  font-style: italic;
}</style>
