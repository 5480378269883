<script>
import TopBar from '../components/TopBar.svelte';
</script>

<style lang="postcss">.twitter {
  display: flex;
  flex-direction: column;
  --border-opacity: 1;
  border-color: #1a202c;
  border-color: rgba(26, 32, 44, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  height: 100%;
}</style>

<svelte:head>
  <script async src="https://platform.twitter.com/widgets.js" charset="utf-8">

  </script>
</svelte:head>

<div class="twitter">
  <header class="h-10">
    <TopBar class="bg-blue-600 text-white">Twitter</TopBar>
  </header>
  <div style="height: calc(100% - 2.5rem)" class="overflow-auto">
    <a
      class="twitter-timeline"
      data-lang="en"
      data-theme="dark"
      href="https://twitter.com/divyun?ref_src=twsrc%5Etfw">
      Loading
    </a>
  </div>
</div>
