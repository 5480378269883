<script>
import TopBar from '../components/TopBar.svelte';
</script>

<style lang="postcss">.spotify {
  display: flex;
  flex-direction: column;
  --bg-opacity: 1;
  background-color: #1a202c;
  background-color: rgba(26, 32, 44, var(--bg-opacity));
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
}</style>

<div class="spotify">
  <div class="h-8">
    <TopBar class="bg-green-900 text-white">Spotify</TopBar>
  </div>
  <iframe
    src="https://open.spotify.com/embed/playlist/4H5xbQF8lC63us5oAJOzRL"
    class="flex-grow"
    frameborder="0"
    allowtransparency="true"
    title="spotify"
    allow="encrypted-media" />
</div>
