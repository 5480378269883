<script>
import leaflet from 'leaflet';
import { onMount } from 'svelte';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerRetinaIcon from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadowIcon from 'leaflet/dist/images/marker-shadow.png';

import TopBar from '../components/TopBar.svelte';

let map;
onMount(() => {
  map = leaflet.map('map').setView([28.5514, 77.3185], 11);

  // eslint-disable-next-line no-underscore-dangle
  delete leaflet.Icon.Default.prototype._getIconUrl;

  leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: markerRetinaIcon,
    iconUrl: markerIcon,
    shadowUrl: markerShadowIcon,
  });

  const osm = leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 14,
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  });

  osm.addTo(map);

  leaflet
    .marker([28.5482, 77.2380])
    .addTo(map)
    .bindPopup('Here I am')
    .openPopup();

  leaflet
    .marker([28.5514, 77.3185])
    .addTo(map)
    .bindPopup('Here I work')
    .openPopup();
});
</script>

<style lang="postcss" global>/* required styles */

:global(.leaflet-pane),
:global(.leaflet-tile),
:global(.leaflet-marker-icon),
:global(.leaflet-marker-shadow),
:global(.leaflet-tile-container),
:global(.leaflet-pane) > :global(svg),
:global(.leaflet-pane) > :global(canvas),
:global(.leaflet-zoom-box),
:global(.leaflet-image-layer),
:global(.leaflet-layer) {
  position: absolute;
  left: 0;
  top: 0;
}

:global(.leaflet-container) {
  overflow: hidden;
}

:global(.leaflet-tile),
:global(.leaflet-marker-icon),
:global(.leaflet-marker-shadow) {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */

:global(.leaflet-tile::selection) {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */

:global(.leaflet-safari) :global(.leaflet-tile) {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */

:global(.leaflet-safari) :global(.leaflet-tile-container) {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

:global(.leaflet-marker-icon),
:global(.leaflet-marker-shadow) {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */

/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */

:global(.leaflet-container) :global(.leaflet-overlay-pane) :global(svg),
:global(.leaflet-container) :global(.leaflet-marker-pane) :global(img),
:global(.leaflet-container) :global(.leaflet-shadow-pane) :global(img),
:global(.leaflet-container) :global(.leaflet-tile-pane) :global(img),
:global(.leaflet-container) :global(img.leaflet-image-layer),
:global(.leaflet-container) :global(.leaflet-tile) {
  max-width: none !important;
  max-height: none !important;
}

:global(.leaflet-container.leaflet-touch-zoom) {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

:global(.leaflet-container.leaflet-touch-drag) {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

:global(.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom) {
  -ms-touch-action: none;
  touch-action: none;
}

:global(.leaflet-container) {
  -webkit-tap-highlight-color: transparent;
}

:global(.leaflet-container) :global(a) {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

:global(.leaflet-tile) {
  filter: inherit;
  visibility: hidden;
}

:global(.leaflet-tile-loaded) {
  visibility: inherit;
}

:global(.leaflet-zoom-box) {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */

:global(.leaflet-overlay-pane) :global(svg) {
  -moz-user-select: none;
}

:global(.leaflet-pane)         { z-index: 400;
}

:global(.leaflet-tile-pane)    { z-index: 200;
}

:global(.leaflet-overlay-pane) { z-index: 400;
}

:global(.leaflet-shadow-pane)  { z-index: 500;
}

:global(.leaflet-marker-pane)  { z-index: 600;
}

:global(.leaflet-tooltip-pane)   { z-index: 650;
}

:global(.leaflet-popup-pane)   { z-index: 700;
}

:global(.leaflet-map-pane) :global(canvas) { z-index: 100;
}

:global(.leaflet-map-pane) :global(svg)    { z-index: 200;
}

:global(.leaflet-vml-shape) {
  width: 1px;
  height: 1px;
}

:global(.lvml) {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

:global(.leaflet-control) {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

:global(.leaflet-top),
:global(.leaflet-bottom) {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

:global(.leaflet-top) {
  top: 0;
}

:global(.leaflet-right) {
  right: 0;
}

:global(.leaflet-bottom) {
  bottom: 0;
}

:global(.leaflet-left) {
  left: 0;
}

:global(.leaflet-control) {
  float: left;
  clear: both;
}

:global(.leaflet-right) :global(.leaflet-control) {
  float: right;
}

:global(.leaflet-top) :global(.leaflet-control) {
  margin-top: 10px;
}

:global(.leaflet-bottom) :global(.leaflet-control) {
  margin-bottom: 10px;
}

:global(.leaflet-left) :global(.leaflet-control) {
  margin-left: 10px;
}

:global(.leaflet-right) :global(.leaflet-control) {
  margin-right: 10px;
}

/* zoom and fade animations */

:global(.leaflet-fade-anim) :global(.leaflet-tile) {
  will-change: opacity;
}

:global(.leaflet-fade-anim) :global(.leaflet-popup) {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

:global(.leaflet-fade-anim) :global(.leaflet-map-pane) :global(.leaflet-popup) {
  opacity: 1;
}

:global(.leaflet-zoom-animated) {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

:global(.leaflet-zoom-anim) :global(.leaflet-zoom-animated) {
  will-change: transform;
}

:global(.leaflet-zoom-anim) :global(.leaflet-zoom-animated) {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
  -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
  transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
}

:global(.leaflet-zoom-anim) :global(.leaflet-tile),
:global(.leaflet-pan-anim) :global(.leaflet-tile) {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

:global(.leaflet-zoom-anim) :global(.leaflet-zoom-hide) {
  visibility: hidden;
}

/* cursors */

:global(.leaflet-interactive) {
  cursor: pointer;
}

:global(.leaflet-grab) {
  cursor: -webkit-grab;
  cursor:    -moz-grab;
  cursor:         grab;
}

:global(.leaflet-crosshair),
:global(.leaflet-crosshair) :global(.leaflet-interactive) {
  cursor: crosshair;
}

:global(.leaflet-popup-pane),
:global(.leaflet-control) {
  cursor: auto;
}

:global(.leaflet-dragging) :global(.leaflet-grab),
:global(.leaflet-dragging) :global(.leaflet-grab) :global(.leaflet-interactive),
:global(.leaflet-dragging) :global(.leaflet-marker-draggable) {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor:    -moz-grabbing;
  cursor:         grabbing;
}

/* marker & overlays interactivity */

:global(.leaflet-marker-icon),
:global(.leaflet-marker-shadow),
:global(.leaflet-image-layer),
:global(.leaflet-pane) > :global(svg) :global(path),
:global(.leaflet-tile-container) {
  pointer-events: none;
}

:global(.leaflet-marker-icon.leaflet-interactive),
:global(.leaflet-image-layer.leaflet-interactive),
:global(.leaflet-pane) > :global(svg) :global(path.leaflet-interactive),
:global(svg.leaflet-image-layer.leaflet-interactive) :global(path) {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

:global(.leaflet-container) {
  background: #ddd;
  outline: 0;
}

:global(.leaflet-container) :global(a) {
  color: #0078A8;
}

:global(.leaflet-container) :global(a.leaflet-active) {
  outline: 2px solid orange;
}

:global(.leaflet-zoom-box) {
  border: 2px dotted #38f;
  background: rgba(255,255,255,0.5);
}

/* general typography */

:global(.leaflet-container) {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

:global(.leaflet-bar) {
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  border-radius: 4px;
}

:global(.leaflet-bar) :global(a),
:global(.leaflet-bar) :global(a:hover) {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

:global(.leaflet-bar) :global(a),
:global(.leaflet-control-layers-toggle) {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

:global(.leaflet-bar) :global(a:hover) {
  background-color: #f4f4f4;
}

:global(.leaflet-bar) :global(a:first-child) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

:global(.leaflet-bar) :global(a:last-child) {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

:global(.leaflet-bar) :global(a.leaflet-disabled) {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

:global(.leaflet-touch) :global(.leaflet-bar) :global(a) {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

:global(.leaflet-touch) :global(.leaflet-bar) :global(a:first-child) {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

:global(.leaflet-touch) :global(.leaflet-bar) :global(a:last-child) {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

:global(.leaflet-control-zoom-in),
:global(.leaflet-control-zoom-out) {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

:global(.leaflet-touch) :global(.leaflet-control-zoom-in), :global(.leaflet-touch) :global(.leaflet-control-zoom-out)  {
  font-size: 22px;
}

/* layers control */

:global(.leaflet-control-layers) {
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
  background: #fff;
  border-radius: 5px;
}

:global(.leaflet-control-layers-toggle) {
  background-image: url(../../node_modules/leaflet/dist/images/layers.png);
  width: 36px;
  height: 36px;
}

:global(.leaflet-retina) :global(.leaflet-control-layers-toggle) {
  background-image: url(../../node_modules/leaflet/dist/images/layers-2x.png);
  background-size: 26px 26px;
}

:global(.leaflet-touch) :global(.leaflet-control-layers-toggle) {
  width: 44px;
  height: 44px;
}

:global(.leaflet-control-layers) :global(.leaflet-control-layers-list),
:global(.leaflet-control-layers-expanded) :global(.leaflet-control-layers-toggle) {
  display: none;
}

:global(.leaflet-control-layers-expanded) :global(.leaflet-control-layers-list) {
  display: block;
  position: relative;
}

:global(.leaflet-control-layers-expanded) {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

:global(.leaflet-control-layers-scrollbar) {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

:global(.leaflet-control-layers-selector) {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

:global(.leaflet-control-layers) :global(label) {
  display: block;
}

:global(.leaflet-control-layers-separator) {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */

:global(.leaflet-default-icon-path) {
  background-image: url(../../node_modules/leaflet/dist/images/marker-icon.png);
}

/* attribution and scale controls */

:global(.leaflet-container) :global(.leaflet-control-attribution) {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

:global(.leaflet-control-attribution),
:global(.leaflet-control-scale-line) {
  padding: 0 5px;
  color: #333;
}

:global(.leaflet-control-attribution) :global(a) {
  text-decoration: none;
}

:global(.leaflet-control-attribution) :global(a:hover) {
  text-decoration: underline;
}

:global(.leaflet-container) :global(.leaflet-control-attribution),
:global(.leaflet-container) :global(.leaflet-control-scale) {
  font-size: 11px;
}

:global(.leaflet-left) :global(.leaflet-control-scale) {
  margin-left: 5px;
}

:global(.leaflet-bottom) :global(.leaflet-control-scale) {
  margin-bottom: 5px;
}

:global(.leaflet-control-scale-line) {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

:global(.leaflet-control-scale-line:not(:first-child)) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

:global(.leaflet-control-scale-line:not(:first-child):not(:last-child)) {
  border-bottom: 2px solid #777;
}

:global(.leaflet-touch) :global(.leaflet-control-attribution),
:global(.leaflet-touch) :global(.leaflet-control-layers),
:global(.leaflet-touch) :global(.leaflet-bar) {
  box-shadow: none;
}

:global(.leaflet-touch) :global(.leaflet-control-layers),
:global(.leaflet-touch) :global(.leaflet-bar) {
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
}

/* popup */

:global(.leaflet-popup) {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

:global(.leaflet-popup-content-wrapper) {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

:global(.leaflet-popup-content) {
  margin: 13px 19px;
  line-height: 1.4;
}

:global(.leaflet-popup-content) :global(p) {
  margin: 18px 0;
}

:global(.leaflet-popup-tip-container) {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

:global(.leaflet-popup-tip) {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

:global(.leaflet-popup-content-wrapper),
:global(.leaflet-popup-tip) {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}

:global(.leaflet-container) :global(a.leaflet-popup-close-button) {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

:global(.leaflet-container) :global(a.leaflet-popup-close-button:hover) {
  color: #999;
}

:global(.leaflet-popup-scrolled) {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

:global(.leaflet-oldie) :global(.leaflet-popup-content-wrapper) {
  zoom: 1;
}

:global(.leaflet-oldie) :global(.leaflet-popup-tip) {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

:global(.leaflet-oldie) :global(.leaflet-popup-tip-container) {
  margin-top: -1px;
}

:global(.leaflet-oldie) :global(.leaflet-control-zoom),
:global(.leaflet-oldie) :global(.leaflet-control-layers),
:global(.leaflet-oldie) :global(.leaflet-popup-content-wrapper),
:global(.leaflet-oldie) :global(.leaflet-popup-tip) {
  border: 1px solid #999;
}

/* div icon */

:global(.leaflet-div-icon) {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */

/* Base styles for the element that has a tooltip */

:global(.leaflet-tooltip) {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

:global(.leaflet-tooltip.leaflet-clickable) {
  cursor: pointer;
  pointer-events: auto;
}

:global(.leaflet-tooltip-top:before),
:global(.leaflet-tooltip-bottom:before),
:global(.leaflet-tooltip-left:before),
:global(.leaflet-tooltip-right:before) {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

:global(.leaflet-tooltip-bottom) {
  margin-top: 6px;
}

:global(.leaflet-tooltip-top) {
  margin-top: -6px;
}

:global(.leaflet-tooltip-bottom:before),
:global(.leaflet-tooltip-top:before) {
  left: 50%;
  margin-left: -6px;
}

:global(.leaflet-tooltip-top:before) {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

:global(.leaflet-tooltip-bottom:before) {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

:global(.leaflet-tooltip-left) {
  margin-left: -6px;
}

:global(.leaflet-tooltip-right) {
  margin-left: 6px;
}

:global(.leaflet-tooltip-left:before),
:global(.leaflet-tooltip-right:before) {
  top: 50%;
  margin-top: -6px;
}

:global(.leaflet-tooltip-left:before) {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

:global(.leaflet-tooltip-right:before) {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

:global(.map) {
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
}</style>

<div class="map">
  <div class="h-10">
    <TopBar>Map</TopBar>
  </div>
  <div class="" style="height: calc(100% - 2.5rem)">
    <div id="map" class="h-full w-full" />
  </div>
</div>
